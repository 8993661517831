$(document).ready(function () {
    initWhitepaperModal();
    initHandleCertGallery();

    if ($('#slideshow .logo').length) {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 100) {
                $('.nav--logo').addClass('is-visible');
            } else {
                $('.nav--logo').removeClass('is-visible');
            }
        }).trigger('scroll');

        $(window).resize(function () {
            $(window).trigger('scroll');
        });
    } else {
        $('.nav--logo').addClass('is-visible');
    }

    $('body').click(function (event) {
        if ($(event.target).closest('#search').length == 0) {
            $('#search').removeClass('show');
        }

        if ($(event.target).closest('#search-keyword').length == 0) {
            $('#search-keyword').animate({
                width: '120px'
            });
        }
    });

    $('header .navigation-menu').click(function () {
        $('header nav').toggleClass('show');
    });

    $('ul li a', 'nav').click(function (event) {
        if ($(this).next('ul').length > 0) {
            event.preventDefault();

            $(this).next('ul').toggle();
        }
    });

    $('#slideshow').slick({
        arrows: false,
        autoplay: true
    });

    $('#search-form').submit(function (e) {
        e.preventDefault();

        window.location.href = '/zoeken/' + $('#search-keyword').val();
    });

    $('#search-keyword').click(function (event) {
        $('#search-keyword').animate({
            width: '200px'
        });
    });

    $('#search-form-2').submit(function (e) {
        e.preventDefault();

        window.location.href = '/zoeken/' + $('#search-keyword-2').val();
    });

    $('#multiple-gallery').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false
    });

    $('#filter-expertise').change(function (e) {
        if (this.value === 'all') {
            window.location.href = window.location.href.split('?')[0];
            return;
        }

        window.location.href = window.location.href.split('?')[0] + '?expertise=' + this.value;
    });

    function initHandleCertGallery(){
        lightGallery(document.getElementById('certificering'), {
            selector: '.cert',
        });
    }

    function initWhitepaperModal() {
        const modal = document.querySelector('#whitepaper-modal');
        if (modal) {
            if (getCookie('hideWhitepaperModal') == null) {
                modal.classList.remove('hide');
            }

            const close = modal.querySelector('.close');

            close.addEventListener('click', function () {
                //hide popup for 180 min = 3 hours
                setCookie('hideWhitepaperModal', true, 60);
                modal.classList.add('hide');
            });


            $('#whitepaper-form button').on('click', function (e) {
                e.preventDefault();
                
                const _this = $(this);
                $('#whitepaper-messages').empty();
                _this.prop('disabled', true);
                const data = $('#whitepaper-form').serialize();

                $.ajax('/whitepaper/send', {
                    accept: "application/json",
                    data: data,
                    type: 'POST',
                    success: function (response) {
                        _this.prop('disabled', false);

                        if (response.success) {
                            response.messages.forEach((message) => {
                                $('#whitepaper-messages').append(createAlert(message, 'alert-success'))
                            });
                        } else {
                            response.messages.forEach((message) => {
                                $('#whitepaper-messages').append(createAlert(message, 'alert-danger'))
                            });
                        }
                    }
                });
            })


        }

        function setCookie(cname, cvalue, exminutes) {
            const d = new Date();
            d.setTime(d.getTime() + (exminutes * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return null;
        }

        function createAlert(message, className) {
            const alert = document.createElement('div');

            alert.classList.add('alert', className);
            alert.innerHTML = message;

            return alert;
        }
    }


});
